import React from 'react';

import { ModuleHelperWrapper } from './components/ModuleHelperWrapper';
import { CosmicModuleType, ModuleType } from './types/cosmicjs';

export type CosmicModuleProps = {
  data: ModuleType;
  stickyNavContent?: any;
  isBlog?: boolean;
};

export type CosmicModule = React.FunctionComponent<CosmicModuleProps>;

type CosmicSet = Partial<Record<CosmicModuleType, CosmicModule>>;

export function cosmicFactory<T = CosmicSet>(set: T) {
  return {
    make(): CosmicModule {
      return function CosmicModule(props: CosmicModuleProps) {
        const moduleType = props.data.type;

        // TODO smth weard happens below, let's fix it
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const Module = set[moduleType] || set[props.data.slug];
        if (!Module) {
          console.warn(`No module for ${moduleType} in this factory`);

          return null;
        }

        return (
          <ModuleHelperWrapper {...props}>
            <Module {...props} />
          </ModuleHelperWrapper>
        );
      };
    },
    withModules(addition: CosmicSet) {
      return cosmicFactory({ ...set, ...addition });
    },
  };
}

export type CosmicFactory = ReturnType<typeof cosmicFactory>;
