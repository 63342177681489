'use client';
import { Button } from '@finn/design-system/atoms/button';
import { FinnWhite } from '@finn/design-system/icons/FINN-white';
import Script from 'next/script';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import { ChatRef } from './types';

interface IntercomChatProps {
  onChatOpened?: () => void;
}

export const IntercomChat = forwardRef<ChatRef, IntercomChatProps>(
  ({ onChatOpened }, ref) => {
    const [showButton, setShowButton] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);

    const initializeIntercom = useCallback(async () => {
      if (typeof window === 'undefined') return;
      try {
        if (isInitialized) {
          return;
        }

        if (!window.intercomSettings) {
          const response = await fetch('/api/generateIntercomToken');

          if (!response.ok) {
            throw new Error(
              `Failed to fetch Intercom token: ${response.status}`
            );
          }

          const resp = await response.json();

          const { user_id, hash, name, email } = resp.data;

          window.intercomSettings = {
            api_base: 'https://api-iam.intercom.io',
            app_id: 'm3b3stgt',
            user_id,
            user_hash: hash,
            hide_default_launcher: true,
            name,
            email,
          };
        }

        if (window.Intercom) {
          window.Intercom('reattach_activator');
          window.Intercom('update', window.intercomSettings);
          window.Intercom('boot', window.intercomSettings);
          setIsInitialized(true);

          if (
            window.intercomSettings?.user_id &&
            window.intercomSettings?.user_hash
          ) {
            setShowButton(true);
          } else {
            setShowButton(false);
          }
        }
      } catch (error) {
        // ignore
      }
    }, [isInitialized]);

    useEffect(() => {
      const initializeOnMount = async () => {
        if (typeof window !== 'undefined' && window.Intercom) {
          await initializeIntercom();
        }
      };

      initializeOnMount();

      return () => {
        if (window.Intercom && isInitialized) {
          window.Intercom('shutdown');
        }
      };
    }, [isInitialized, initializeIntercom]);

    const openChat = useCallback(() => {
      if (window.Intercom) {
        window.Intercom('show');
        if (onChatOpened) {
          onChatOpened();
        }
      }
    }, [onChatOpened]);

    useImperativeHandle(
      ref,
      () => ({
        openChat,
        openWidget: openChat,
        closeWidget: () => {
          if (window.Intercom) {
            window.Intercom('hide');
          }
        },
      }),
      [openChat]
    );

    return (
      <>
        <Script
          src="https://widget.intercom.io/widget/m3b3stgt"
          strategy="lazyOnload"
          onLoad={initializeIntercom}
        />
        {showButton && (
          <Button
            onClick={openChat}
            className="fixed bottom-6 right-6 size-12 rounded-full bg-black shadow-lg"
            aria-label="Chat Unterstützung"
            variant="circle"
          >
            <FinnWhite className="size-6" />
          </Button>
        )}
      </>
    );
  }
);
