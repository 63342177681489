import {
  Drawer,
  DrawerContent,
  DrawerFooter,
} from '@finn/design-system/atoms/drawer';
import { useMediaQuery } from '@finn/design-system/helpers/media';
import {
  Features,
  useDownPayment,
  useIsABVariant,
  useIsPayAsYouGo,
  usePCPLoginExp,
} from '@finn/ua-featureflags';
import {
  interactionTrackingEvent,
  TrackingEventName,
  useTrackingStore,
} from '@finn/ua-tracking';
import { cn, getFormattedPrice, useIntl } from '@finn/ui-utils';
import {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { getForBusinessCookie } from '../../../helpers';
import { useDiscoveryConfig } from '../../core/data/MetadataProvider';
import { VehicleHeader } from '../../pdp';
import {
  DirectCheckout,
  getShortestKmOption,
  useConfigureStore,
} from '../../pdp-management';
import { VehicleContext } from '../../product-cards';
import {
  calculateTotalPrice,
  PartialCartInfo,
  useMonthlySubscriptionCost,
  usePartnerDiscount,
  usePreDiscountRawValue,
} from '../../product-pricing';
import { triggerEcommerceOrderingTrackingEvent } from '../../tracking';
import { ContinueButton } from './ContinueButton';
import { ContractDuration } from './ContractDuration';
import { DistanceOptions } from './DistanceOptions';
import { DownPayment } from './DownPayment';
import { PayAsYouGoFakedoor } from './PayAsYouGoFakedoor';
import { PriceDisplay } from './PriceDisplay';

enum PCPStep {
  ConfigureStep = 1,
  PayAsYouGoStep = 2,
  LoginStep = 3,
  DownPaymentStep = 4,
}
const stepNameMap: Record<PCPStep, string> = {
  [PCPStep.ConfigureStep]: 'configure',
  [PCPStep.PayAsYouGoStep]: 'pay-as-you-go',
  [PCPStep.LoginStep]: 'login',
  [PCPStep.DownPaymentStep]: 'downpayment',
};

type ConfigureVehicleModalProps = {
  open: boolean;
  onClose: () => void;
  loadingEdit?: boolean;
  handleEditConfig?: () => void;
  closedUserGroupId?: string;
  cartInfo?: PartialCartInfo;
  buildLoginSection: ReactNode;
  extendedAccountInProgress?: boolean;
  directCheckout?: DirectCheckout;
  session?: object;
  vehicleHeaderChildren?: ReactNode;
};

const ANIMATION_TIMEOUT = 300;

export const ConfigureVehicleModal = ({
  open,
  onClose,
  handleEditConfig,
  loadingEdit,
  closedUserGroupId,
  cartInfo,
  buildLoginSection,
  extendedAccountInProgress,
  directCheckout,
  session,
  vehicleHeaderChildren,
}: ConfigureVehicleModalProps) => {
  const { isDownPaymentEnabled } = useDownPayment();
  const intl = useIntl();
  const partnerDiscount = usePartnerDiscount({
    closedUserGroupId,
    cartInfo,
  });
  const isExpPCPPayAsYouGo = useIsABVariant(Features.ExpPCPPayAsYouGo);
  const track = useTrackingStore((state) => state.track);

  useEffect(() => {
    if (partnerDiscount) {
      track(TrackingEventName.PCP_PARTNER_DISCOUNT_SHOWED, {
        additionalProps: { partnerDiscount },
      });
    }
  }, [partnerDiscount, track]);

  const { vehicle } = useContext(VehicleContext);

  const downPaymentAmountInVehicle =
    vehicle?.downpayment_prices?.downpayment_fixed_amount;

  const {
    term,
    onContinue,
    downPayment: isDownPayment,
    isLoading: isLoadingFromStore,
    kilometerPackage,
    monthlyPrice,
    setKilometerPackage,
  } = useConfigureStore();
  const isPayAsYouGo = useIsPayAsYouGo(kilometerPackage);
  const [isPayAsYouGoCTAClicked, setIsPayAsYouGoCTAClicked] = useState(false);
  const showPayAsYouGoFakedoor = isPayAsYouGo && !isPayAsYouGoCTAClicked;

  const [continueDisabled, setContinueDisabled] = useState(false);

  const monthlyPriceWithoutDownpayment = calculateTotalPrice({
    vehicle,
    kilometerPackage,
    term,
    isDownPayment: false,
  });

  const [step, setStep] = useState({
    prevStep: null,
    currentStep: PCPStep.ConfigureStep,
  });
  const { prevStep, currentStep } = step;

  const isCheckoutEditModal = Boolean(handleEditConfig);

  const { isEnabled: isExpPCPLogin } = usePCPLoginExp();
  const scrollContainer = useRef<HTMLDivElement>(null);

  const isForBusiness = getForBusinessCookie();
  const { forceRegularPrice } = useDiscoveryConfig();

  const { total, monthlyCost } = useMonthlySubscriptionCost(vehicle);
  let totalWithDiscount = total;
  let preDiscountPrice = usePreDiscountRawValue({
    vehicle,
    price: monthlyCost,
    term,
    km: kilometerPackage,
    isForBusiness,
    isDownPayment,
  });

  if (partnerDiscount > 0) {
    if (!preDiscountPrice) {
      preDiscountPrice = total;
    }
    totalWithDiscount = total - total * partnerDiscount;
  }

  const originalPriceFormatted = getFormattedPrice(
    intl,
    preDiscountPrice || 0,
    0
  );
  const finalPriceFormatted = getFormattedPrice(intl, totalWithDiscount, 0);

  const subtitle = intl.formatMessage(
    `pdp.price.${isForBusiness || forceRegularPrice ? 'b2b' : 'b2c'}_vat`
  );

  const isShortPreDiscountPrice = preDiscountPrice?.toString().length === 4;

  const handleModalClose = useCallback(() => {
    interactionTrackingEvent(TrackingEventName.MODAL_CLOSED, {
      name: 'configure',
      location: stepNameMap[currentStep],
    });
    onClose();
  }, [onClose]);

  const changeStep = useCallback(
    (newStep: PCPStep) => {
      setStep({
        prevStep: currentStep,
        currentStep: newStep,
      });
    },
    [currentStep]
  );

  const tempDisableContinue = useCallback(() => {
    setContinueDisabled(true);
    setTimeout(() => setContinueDisabled(false), ANIMATION_TIMEOUT);
  }, []);

  const [submitting, setSubmitting] = useState(false);

  const handleContinue = useCallback(async () => {
    scrollContainer.current?.scrollTo(0, 0);
    if (handleEditConfig) {
      handleEditConfig();

      return;
    }

    if (showPayAsYouGoFakedoor && currentStep === PCPStep.ConfigureStep) {
      changeStep(PCPStep.PayAsYouGoStep);
      interactionTrackingEvent(TrackingEventName.COMPONENT_VIEWED, {
        name: 'Pay as you go fakedoor',
      });
      tempDisableContinue();

      return;
    }

    if (currentStep === PCPStep.ConfigureStep && isDownPaymentEnabled) {
      changeStep(PCPStep.DownPaymentStep);
      tempDisableContinue();

      return;
    }

    if (isExpPCPLogin && currentStep !== PCPStep.LoginStep && !session) {
      changeStep(PCPStep.LoginStep);
      tempDisableContinue();
      triggerEcommerceOrderingTrackingEvent({
        eventName: TrackingEventName.PRODUCT_ADDED,
        kmPackage: kilometerPackage,
        monthlyPrice,
        monthlyPriceWithoutDownpayment,
        downPaymentAmount: isDownPayment
          ? downPaymentAmountInVehicle
          : undefined,
        term,
        vehicle,
      });

      return;
    }

    try {
      setSubmitting(true);
      await onContinue(directCheckout);
    } catch (error) {
      // ignore
    } finally {
      setSubmitting(false);
    }
  }, [
    changeStep,
    handleEditConfig,
    isExpPCPLogin,
    showPayAsYouGoFakedoor,
    onContinue,
  ]);

  useEffect(() => {
    if (
      session &&
      !extendedAccountInProgress &&
      currentStep === PCPStep.LoginStep
    ) {
      onContinue(directCheckout);
    }
  }, [currentStep, extendedAccountInProgress, session]);

  const isMDScreen = useMediaQuery('md');

  const buildConfigureSection = useMemo(() => {
    return (
      <>
        <VehicleHeader vehicle={vehicle}>{vehicleHeaderChildren}</VehicleHeader>
        <ContractDuration />
        <DistanceOptions
          showPayAsYouGo={isExpPCPPayAsYouGo && !isPayAsYouGoCTAClicked}
        />
      </>
    );
  }, [
    vehicle,
    isCheckoutEditModal,
    vehicleHeaderChildren,
    isPayAsYouGoCTAClicked,
    isExpPCPPayAsYouGo,
  ]);

  const buildDownPaymentSection = useMemo(() => {
    return (
      <>
        <VehicleHeader vehicle={vehicle}>{vehicleHeaderChildren}</VehicleHeader>
        <DownPayment />
      </>
    );
  }, [vehicle, vehicleHeaderChildren]);

  const buildPayAsYouGoSection = useMemo(() => {
    return showPayAsYouGoFakedoor ? <PayAsYouGoFakedoor /> : null;
  }, [showPayAsYouGoFakedoor]);

  const buildFooterSection = useMemo(() => {
    const location =
      currentStep === PCPStep.ConfigureStep ? 'configure' : 'downpayment';

    return (
      <DrawerFooter className="z-overlay">
        <PriceDisplay
          subtitle={subtitle}
          finalPrice={finalPriceFormatted}
          originalPrice={originalPriceFormatted}
          isShortPrice={isShortPreDiscountPrice}
          hasOriginalPrice={Boolean(preDiscountPrice)}
        />

        <div className="w-1/2">
          <ContinueButton
            isInPDP
            testId="submit-config"
            location={location}
            onClick={handleContinue}
            isLoading={
              isLoadingFromStore ||
              loadingEdit ||
              continueDisabled ||
              submitting
            }
          />
        </div>
      </DrawerFooter>
    );
  }, [
    submitting,
    currentStep,
    subtitle,
    finalPriceFormatted,
    originalPriceFormatted,
    isShortPreDiscountPrice,
    preDiscountPrice,
    handleContinue,
    isLoadingFromStore,
    loadingEdit,
    continueDisabled,
  ]);

  const handlePayAsYouGoCTA = useCallback(() => {
    setIsPayAsYouGoCTAClicked(true);
    setKilometerPackage(
      getShortestKmOption(vehicle, { forceRegularPrice, term })
    );
    changeStep(PCPStep.ConfigureStep);
  }, [changeStep, vehicle, forceRegularPrice, term, setKilometerPackage]);

  const buildPayAsYouGoFooterSection = useMemo(() => {
    if (!showPayAsYouGoFakedoor) {
      return null;
    }

    return (
      <DrawerFooter className="z-overlay">
        <ContinueButton
          isInPDP
          testId="submit-config"
          location="pay-as-you-go"
          onClick={handlePayAsYouGoCTA}
          title={intl.formatMessage('pdp.payAsYouGo.fakedoor.cta')}
          isLoading={
            isLoadingFromStore || loadingEdit || continueDisabled || submitting
          }
        />
      </DrawerFooter>
    );
  }, [
    intl,
    handlePayAsYouGoCTA,
    isLoadingFromStore,
    loadingEdit,
    continueDisabled,
    submitting,
    showPayAsYouGoFakedoor,
  ]);

  const backButton = useMemo(() => {
    if ([PCPStep.ConfigureStep, PCPStep.PayAsYouGoStep].includes(currentStep)) {
      return;
    }

    if (isExpPCPLogin && currentStep === PCPStep.LoginStep) {
      return () => {
        interactionTrackingEvent(TrackingEventName.BACK_BUTTON_CLICKED, {
          location: stepNameMap[currentStep],
        });

        changeStep(PCPStep.DownPaymentStep);
      };
    }

    return () => {
      interactionTrackingEvent(TrackingEventName.BACK_BUTTON_CLICKED, {
        location: stepNameMap[currentStep],
      });

      changeStep(PCPStep.ConfigureStep);
    };
  }, [currentStep, isExpPCPLogin]);

  const isNotLoginStep = currentStep !== PCPStep.LoginStep;
  const isPayAsyouGoStep = currentStep === PCPStep.PayAsYouGoStep;
  const showFallbackFooter = isNotLoginStep && !isPayAsyouGoStep;

  return (
    <Drawer
      open={open}
      onClose={handleModalClose}
      handleOnly
      direction={isMDScreen ? 'bottom' : 'right'}
    >
      <DrawerContent
        title={
          !isPayAsyouGoStep && intl.formatMessage('pdp.configure.generalTitle')
        }
        onBack={backButton}
        // TODO: test that nothing broke here
        className="z-modalOverlay min-h-dvh !select-text overflow-y-hidden [&>div]:h-full"
      >
        <div
          ref={scrollContainer}
          className={cn(
            'border-pearl absolute left-0 top-14 h-full w-full grow overflow-y-auto overflow-x-hidden border-0 border-t border-solid md:top-[72px]',
            currentStep === PCPStep.ConfigureStep &&
              'max-h-[calc(100%-10rem)] md:max-h-[calc(100%-11rem)]'
          )}
        >
          <div
            id="configure-step"
            className={cn(
              'absolute left-0 top-0 flex h-full w-full flex-col gap-8 bg-white px-4 py-6 transition-[transform,opacity] md:px-20',
              currentStep === PCPStep.ConfigureStep
                ? 'z-overlay translate-x-0 opacity-100'
                : `z-base -translate-x-28 opacity-0`
            )}
          >
            {buildConfigureSection}
          </div>

          {showPayAsYouGoFakedoor && (
            <div
              id="pay-as-you-go-step"
              className={cn(
                'absolute left-0 top-0 flex h-full w-full flex-col gap-8 bg-white px-4 py-6 transition-[transform,opacity] md:px-20',
                currentStep === PCPStep.PayAsYouGoStep
                  ? 'z-overlay translate-x-0 opacity-100'
                  : `z-base -top-full ${prevStep > currentStep ? '-' : ''}translate-x-28 opacity-0`
              )}
            >
              {buildPayAsYouGoSection}
            </div>
          )}

          <div
            className={cn(
              'absolute left-0 top-0 flex h-full w-full flex-col gap-8 bg-white px-4 py-6 transition-[transform,opacity] md:px-20',
              currentStep === PCPStep.DownPaymentStep
                ? 'z-overlay translate-x-0 opacity-100'
                : `z-base -top-full ${prevStep > currentStep ? '-' : ''}translate-x-28 opacity-0`
            )}
          >
            {buildDownPaymentSection}
          </div>

          <div
            id="login-step"
            className={cn(
              'absolute left-0 top-0 mb-16 flex w-full flex-col bg-white px-4 py-6 pb-[6rem] transition-[transform,opacity] md:px-20',
              currentStep === PCPStep.LoginStep
                ? 'z-overlay translate-x-0 opacity-100'
                : 'z-base -top-full translate-x-28 opacity-0'
            )}
          >
            {buildLoginSection}
          </div>
        </div>
        {isPayAsyouGoStep && buildPayAsYouGoFooterSection}
        {showFallbackFooter && buildFooterSection}
      </DrawerContent>
    </Drawer>
  );
};
